<template>
    <v-list nav dense>
        <v-list-item>
            <v-list-item-content>
                <div class="d-flex flex-column w-100">
                    <span class="caption text-uppercase">manage space</span>
                    <v-divider class="mt-1" color="grey"></v-divider>
                </div>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            :to="{
                name: 'space-user-management',
                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
            }"
            v-if="isSpaceAdmin">
            <v-list-item-title>
                <div class="subtitle-2 d-flex align-center">
                    <v-icon small class="mr-1">mdi-account-group-outline</v-icon>
                    <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="mr-2">Course Users / Instances</span>
                    <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="mr-2">Project Users / Instances</span>
                    <span v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="mr-2">Dataset Users / Instances</span>
                </div>
            </v-list-item-title>
        </v-list-item>
        <v-list-item
            :to="{
                name: 'space-configuration',
                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
            }">
            <v-list-item-title>
                <div class="subtitle-2 d-flex align-center">
                    <v-icon small class="mr-1">tune</v-icon>
                    <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="mr-2">Course Configuration</span>
                    <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="mr-2">Project Configuration</span>
                    <span v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="mr-2">Dataset Configuration</span>
                </div>
            </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="currentSpace.vimeo_enabled && isSpaceAdmin" :to="{ name: 'vimeo-videos' }">
            <v-list-item-title>
                <div class="subtitle-2 d-flex align-center">
                    <v-icon small class="mr-1">video_library</v-icon>
                    <span class="mr-2">Video Library</span>
                </div>
            </v-list-item-title>
        </v-list-item>
        <v-list-item
            v-if="(currentSpaceType !== spaceTypes.VENDOR_SPACE && isSpaceAdmin) || userInfo.is_sysadmin"
            :to="{
                name: 'space-delete',
                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
            }">
            <v-list-item-title>
                <div class="subtitle-2 d-flex align-center">
                    <v-icon class="mr-1" small>delete</v-icon>
                    Delete space
                </div>
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
export default {
    name: 'SettingsMenu',
    mixins: [enumsData],
    computed: {
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceAdmin']),
        ...mapState('spaceStore', ['currentSpace']),
        ...mapState(['userInfo'])
    }
}
</script>
