var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v("manage space")]),_c('v-divider',{staticClass:"mt-1",attrs:{"color":"grey"}})],1)])],1),(_vm.isSpaceAdmin)?_c('v-list-item',{attrs:{"to":{
            name: 'space-user-management',
            params: { oid: _vm.$route.params.oid, sid: _vm.$route.params.sid, iid: _vm.$route.params.iid, snid: _vm.$route.params.snid }
        }}},[_c('v-list-item-title',[_c('div',{staticClass:"subtitle-2 d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account-group-outline")]),(_vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE)?_c('span',{staticClass:"mr-2"},[_vm._v("Course Users / Instances")]):(_vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE)?_c('span',{staticClass:"mr-2"},[_vm._v("Project Users / Instances")]):(_vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE)?_c('span',{staticClass:"mr-2"},[_vm._v("Dataset Users / Instances")]):_vm._e()],1)])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{
            name: 'space-configuration',
            params: { oid: _vm.$route.params.oid, sid: _vm.$route.params.sid, iid: _vm.$route.params.iid, snid: _vm.$route.params.snid }
        }}},[_c('v-list-item-title',[_c('div',{staticClass:"subtitle-2 d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("tune")]),(_vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE)?_c('span',{staticClass:"mr-2"},[_vm._v("Course Configuration")]):(_vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE)?_c('span',{staticClass:"mr-2"},[_vm._v("Project Configuration")]):(_vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE)?_c('span',{staticClass:"mr-2"},[_vm._v("Dataset Configuration")]):_vm._e()],1)])],1),(_vm.currentSpace.vimeo_enabled && _vm.isSpaceAdmin)?_c('v-list-item',{attrs:{"to":{ name: 'vimeo-videos' }}},[_c('v-list-item-title',[_c('div',{staticClass:"subtitle-2 d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("video_library")]),_c('span',{staticClass:"mr-2"},[_vm._v("Video Library")])],1)])],1):_vm._e(),((_vm.currentSpaceType !== _vm.spaceTypes.VENDOR_SPACE && _vm.isSpaceAdmin) || _vm.userInfo.is_sysadmin)?_c('v-list-item',{attrs:{"to":{
            name: 'space-delete',
            params: { oid: _vm.$route.params.oid, sid: _vm.$route.params.sid, iid: _vm.$route.params.iid, snid: _vm.$route.params.snid }
        }}},[_c('v-list-item-title',[_c('div',{staticClass:"subtitle-2 d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("delete")]),_vm._v(" Delete space ")],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }